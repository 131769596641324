/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import SelectBox from 'devextreme-react/select-box';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import DataGrid, { Column, Export } from 'devextreme-react/data-grid';

import 'devextreme-react/text-area';
import API from '../../../services/API';
import useAuth from '../../../hooks/useAuth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BusinessUser } from '@youga/youga-interfaces';

const AnalyticsSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [analyticsFiles, setAnalyticsFiles] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [businessUsers, setBusinessUsers] = useState<BusinessUser[]>([]);

  useEffect(() => {
    const fetchBusinessUsers = async () => {
      try {
        if (token == null) {
          throw new Error(`Token not defined.`);
        }
        const result = await API.getAnalitycs(token);
        setAnalyticsFiles(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchBusinessUsers();
  }, [token]);

  const submitAnalyticsRequest = async () => {
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }
      await API.getAnalyticsData(token, {
        selectedClient,
        startDate,
        endDate,
        clientName: businessUsers.filter((c) => c.id === selectedClient)[0]
          .companyName,
      });
    } catch (error) {
      console.error('Error in analytics request:', error);
    }
  };


  useEffect(() => {
    const fetchBusinessUsers = async () => {
      try {
        if (token == null) {
          throw new Error(`Token not defined.`);
        }
        const result = await API.getBuisnessCustomers(token);
        setBusinessUsers(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchBusinessUsers();
  }, [token]);

console.log({businessUsers: analyticsFiles})
  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <div
        sx={{
          padding: '115px',
          maxWidth: '9150px',
          margin: 'auto',
        }}
      >
        <div>
          <SelectBox
            items={businessUsers.map((c) => ({
              id: c.id,
              name: c.companyName,
            }))}
            displayExpr="name"
            valueExpr="id"
            value={selectedClient}
            onValueChanged={(e) => setSelectedClient(e.value)}
          />
          <DatePicker
            selected={startDate}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onChange={(date) => setStartDate(date!)}
          />
          <DatePicker
            selected={endDate}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onChange={(date) => setEndDate(date!)}
          />
          <button onClick={submitAnalyticsRequest}>Submit</button>
        </div>
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Pliki
        </Text>
        <div id="data-grid-demo">
          <DataGrid
            dataSource={analyticsFiles}
            keyExpr="fileName"
            showBorders={true}
          >
            <Column
              dataField="cancellationDate"
              caption="Pobierz"
              width={170}
              cellRender={(row) => {
                return (
                  <a
                    href={row.data.downloadLink}
                    target="_blank"
                    download="report.pdf"
                  >
                    Pobierz
                  </a>
                );
              }}
            />
            <Column
              dataField="fileName"
              caption="Nazwa pliku"
              allowEditing={false}
            />
            <Column
              dataField="lastModified"
              caption="Data"
              allowEditing={false}
              cellRender={({ data }) => {
                const date = new Date(data.lastModified);
                return (
                  <span>
                    {date.toLocaleDateString()} {date.toLocaleTimeString()}
                  </span>
                );
              }}
            />

            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>

      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default AnalyticsSection;
